@tailwind base;
@tailwind components;
@tailwind utilities;

ul, ol {
    list-style: revert;
    padding-left: 40px;
}
button[role='switch'][aria-checked='false'],
button.ant-switch{
    background-color: #B3BAC3 !important;
}
button[role='switch'][aria-checked='true'],
button[role='switch'].same-style,
.ant-btn.ant-btn-primary{
    background-color: #007DBC !important;
}

input.ant-select-selection-search-input {
    --tw-ring-color: transparent !important;
}

span.anticon{
    display: inline-flex;
}

.ant-checkbox.ant-checkbox-checked .ant-checkbox-inner{
    background-color: #007DBC !important;
    border-color: #007DBC !important;
}

.ant-select .anticon.anticon-close-circle{
    display: flex;
    margin-top: -1px;
}

.ant-table .ant-table-body{
    max-height: 68vh !important;
}

/* ------------------- Scroll Bar ---------------- */

* {
    scrollbar-width: thin !important;
    scrollbar-color: #B3BAC3 transparent !important;
}

/* Firefox */
html {
    scrollbar-width: thin !important;
    scrollbar-color: #B3BAC3 transparent !important;
}

.ant-picker-calendar * {
    scrollbar-width: none !important;
}

/* ------------------- Descriptions -------------------- */
.ant-descriptions-item-label {
    background-color: #EFF1F3 !important;
}